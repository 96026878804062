import { CommonService } from './../../../services/common.service';
import { DbService } from './../../../services/db.service';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  request = {
    requestId: '',
    name: '',
    email: '',
    title: '',
    content: '',
    dateCreated: '',
    confirmSwitch: false,
  }
  sections$;
  isMobile;
  companyInfo$;
  constructor(
    private db: DbService,
    public platform: Platform,
    private common: CommonService,
  ) {
    this.sections$ = this.db.collection$(`section`, ref => ref.where('switch', '==', true).orderBy('order', 'asc'));
    this.companyInfo$ = this.db.doc$(`companyInfo/startweb`);
    if (platform.is('mobileweb') || platform.is('mobile')) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    console.log('this.isMobile', this.isMobile);
  }

  ngOnInit(): void {
  }

  // tslint:disable-next-line: typedef
  submit() {
    this.request.requestId = this.common.generateFilename();
    this.request.dateCreated = new Date().toISOString();

    console.log(this.request);
    this.db.updateAt(`request/${this.request.requestId}`, this.request).then(success => {
      alert('문의가 접수되었습니다');
      this.formInit();
    });

  }

  // tslint:disable-next-line: typedef
  formInit() {
    this.request.requestId = '';
    this.request.name = '';
    this.request.email = '';
    this.request.title = '';
    this.request.content = '';
  }
}
