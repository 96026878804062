// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDsPChDwKs6cV7yodBhOPwMO8MFMeH8qz8',
    authDomain: 'startweb33.firebaseapp.com',
    databaseURL: 'https://startweb33.firebaseio.com',
    projectId: 'startweb33',
    storageBucket: 'startweb33.appspot.com',
    messagingSenderId: '519289016233',
    appId: '1:519289016233:web:793ec03489142e6418beb8',
    measurementId: 'G-CCC2TEPBQL'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
