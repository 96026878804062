<div class="wrap">
  <div *ngIf="sections$ | async as sections">
    <div *ngFor="let item of sections">
      <div id="{{item.title}} com">
        <img class="pcBg" [src]='item.img' alt="메인이미지" *ngIf="!isMobile" />
        <img class="mBg" [src]='item.mimg' alt="메인이미지" *ngIf="isMobile" />
      </div>
    </div>
  </div>
  <!-- <div class="portable">
    <h2>
      바이바이
      <span class="yellow">휴대용 향균핸들커버는?</span>
    </h2>
    <p class="conTxt pc">
      앞으로 코로나바이러스 외 변종 바이러스를 일상생활에서 적절히 대처할 수
      있는 지속적 상품! <br />
      바지, 재킷주머니, 핸드백에 휴대하고 공유 차량 및 타 차량에 적극 활용
      개인을 넘어 기업, 관공서, <br />
      모든 공유 차량에 손에 장갑 착용하듯 간단히 설치해서 바이러스에 대처할 수
      있는 획기적 아이템!
    </p>
    <p class="conTxt mobile">
      앞으로 코로나바이러스 외 변종 바이러스를<br />
      일상생활에서 적절히 대처할 수 있는 지속적 상품!<br />
      바지. 재킷 주머니, 핸드백에 휴대하고 공유 차량 및<br />
      타 차량에 적극 활용 개인을 넘어 기업, 관공서,<br />
      모든 공유 차량에 손에 장갑 착용하듯 간단히 설치해서<br />
      바이러스에 대처할 수 있는 획기적 아이템!
    </p>
  </div>
  <div class="distinction">
    <h2>
      바이바이 휴대용 향균핸들커버의
      <span class="yellow">차별성</span>
    </h2>
    <p class="conTxt pc">
      현재 전 세계 마켓, 시장에는 없었던
      <span class="blue">휴대, 일회용(100회 이상 사용 가능)</span> 향균
      핸들커버입니다. <br />
      카세어링 업체, 렌터카 업체, 대리운전 업체, 관공서 차량, 택배 업체, 관광
      운수 업체, <br />
      브랜드 자동차 업체, 모든 영업용 차량 회사에 사용
    </p>
    <p class="conTxt mobile">
      현재 전 세계 마켓, 시장에는 없었던<br />
      <span class="blue">휴대, 일회용(100회 이상 사용 가능)</span> 향균
      핸들커버입니다.<br />
      카세어링 업체, 렌터카 업체, 대리운전 업체,<br />
      관공서 차량, 택배 업체, 관광 운수 업체,<br />
      브랜드 자동차 업체, 모든 영업용 차량 회사에 사용
    </p>
  </div> -->
  <div class="test">
    <!-- <div class="result">
      <h2>
        시험결과
        <span class="yellow">합격!</span>
      </h2>
      <div class="report cf">
        <ul class="left">
          <li class="mb mobile">
            <img src="assets/img/test_img_01.png" alt="시험결과보고서 이미지" />
          </li>
          <li>
            <img src="assets/img/test_img_04.png" alt="시험결과보고서 이미지" />
          </li>
        </ul>
        <ul class="right">
          <li class="mb">
            <img src="assets/img/test_img_02" alt="시험결과보고서 이미지" />
          </li>
          <li class="mobile">
            <img src="assets/img/test_img_03" alt="시험결과보고서 이미지" />
          </li>
        </ul>
      </div>
    </div> -->
    <div *ngIf="companyInfo$ | async as companyInfo">
      <div class="video">
        <!-- <h2>착용 영상</h2> -->
        <video src="assets/img/video.mp4" width="1280" height="800" controls playsinline></video>
        <img class="tLogo" [src]=companyInfo.logo alt="로고이미지" />
        <p>
          베이직한 디자인과 우수한 향균능력! <br />
          우수한 휴대성으로 어디든 보관가능!<br />
          나와 우리 가족을 지키는 바이러스 예방!
        </p>
      </div>
      <!-- </div> -->
    </div>
    <div class="inquiry">
      <h3>문의하기</h3>
      <form action="#">
        <div>
          <label for="name">이름</label>
          <input type="text" name="name" [(ngModel)]="request.name" />
        </div>
        <div>
          <label for="email">이메일 주소</label>
          <input type="text" name="email" [(ngModel)]="request.email" />
        </div>
        <div>
          <label for="title">제목</label>
          <input type="text" name="title" [(ngModel)]="request.title" />
        </div>
        <div class="subs">
          <span class="subsTit">내용</span>
          <textarea name="subscript" id="subscript" [(ngModel)]="request.content"></textarea>
        </div>
        <button type="submit" (click)='submit()'
          [disabled]='!request.name || !request.email || !request.title || !request.content'>보내기</button>
      </form>
    </div>
  </div>